import useStore from "../zustand";
export default function Contact() {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          color: "#1E293B",
          cursor: "pointer",
          padding: 4,
          textAlign: "center",
          width: "50%",
          backgroundColor: "#FDE68A",
        }}
        onClick={() => {
          // setOpen(!open);
          useStore.setState({ opencontact: true });
        }}
      >
        Contact <div className="at">@</div>
      </div>{" "}
      <div
        style={{
          padding: 4,
          textAlign: "center",
          width: "50%",
          color: "#1E293B",
          cursor: "pointer",
          backgroundColor: "#8ae0fd",
        }}
      >
        <a
          href="https://travel.unprofessional.studio/"
          style={{ color: "black" }}
        >
          Travel
        </a>
      </div>
    </div>
  );
}
