import { useState, Suspense, useEffect, useRef } from "react";

import Masonry from "./Masonry/Masonry";
import Content from "./Content/Content";

import { suspend } from "suspend-react";
import useStore from "./zustand";
import produce from "immer";
import Button from "./Button/Button";
import Intro from "./Intro";
import Contact from "./Contact";

function getUnique(arr, index) {
  const unique = arr
    .map((e) => e[index])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
}
function Shuffle({
  images,
  documents,
  webgls,
  texts,
  videos,
  publications,
  iphonevideos,
}) {
  const [shuffled, setShuffled] = useState();
  const [ready, setReady] = useState([]);

  useEffect(
    () =>
      setShuffled([
        ...images,
        ...iphonevideos,
        ...documents,
        ...webgls,
        ...texts,
        ...videos,
        ...publications,
      ]),
    [images, documents, webgls, texts, videos, publications, iphonevideos]
  );
  useEffect(() => {
    if (shuffled) {
      setReady(getUnique(shuffled, "id"));
    }
  }, [shuffled]);
  return shuffled ? <Masonry data={ready} /> : <></>;
}
function Filter({ data }) {
  const selectedtitles = useStore((s) => s.selectedtitles);
  const selectedtags = useStore((s) => s.selectedtags);
  const [images, setImages] = useState([]);
  const [iphonevideos, setIphonevideos] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [texts, setTexts] = useState([]);
  const [webgls, setWebgls] = useState([]);
  const [videos, setVideos] = useState([]);
  const [publications, setPublications] = useState([]);
  function resetTags() {
    setImages([]);
    setIphonevideos([]);
    setDocuments([]);
    setWebgls([]);
    setTexts([]);
    setVideos([]);
    setPublications([]);
  }
  useEffect(() => {
    if (data) {
      data.map((item) => {
        // project title as tag
        if (item.title !== undefined) {
          useStore.setState(
            produce((state) => {
              if (
                !state.titletags.includes(item.title) &&
                item.title !== "NOT A PROJECT"
              )
                state.titletags.push(item.title);
            })
          );
        }

        // push tags to zustand
        if (item.tags) {
          item.tags.split(", ").map((tag) => {
            // useStore.setState((state) => state.projecttags.push(tag))
            useStore.setState(
              produce((state) => {
                if (!state.projecttags.includes(tag))
                  state.projecttags.push(tag);
              })
            );
          });
        }
        // console.log(item);
        if (item.contact) {
          // useStore.setState((state) => (state.contact = item.contact));
          useStore.setState(
            produce((state) => {
              state.contact = item.contact;
            })
          );
        }
        if (item.contacttwn) {
          // useStore.setState((state) => (state.contact = item.contact));
          useStore.setState(
            produce((state) => {
              state.contacttwn = item.contacttwn;
            })
          );
        }
      });
    }
  }, [data]);

  useEffect(() => {
    // reset stores
    resetTags();

    if (data) {
      data.map((item) => {
        // push images
        if (item.images) {
          // loop all images
          item.images.map((image) => {
            // console.log(image);
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                if (
                  selectedtags.every((i) => image.tags.split(", ").includes(i))
                ) {
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                // no tag and no title is selcted
              } else {
                setImages((images) => [
                  ...images,
                  {
                    url: image.url,
                    small: image.small,
                    big: image.big,
                    width: image.width,
                    height: image.height,
                    tags: image.tags,
                    id: image.id,
                    title: image.title,
                    type: image.type,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setImages((images) => [
                      ...images,
                      {
                        url: image.url,
                        small: image.small,
                        big: image.big,
                        width: image.width,
                        height: image.height,
                        tags: image.tags,
                        id: image.id,
                        title: image.title,
                        type: image.type,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  if (
                    selectedtags.every((i) =>
                      image.tags.split(", ").includes(i)
                    )
                  ) {
                    if (item.title === projecttitle) {
                      setImages((images) => [
                        ...images,
                        {
                          url: image.url,
                          small: image.small,
                          big: image.big,
                          width: image.width,
                          height: image.height,
                          tags: image.tags,
                          id: image.id,
                          title: image.title,
                          type: image.type,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                });
              }
            }
          });
        }
        if (item.iphonevideos) {
          // loop all iphonevideo
          item.iphonevideos.map((iphonevideo) => {
            // console.log(image);
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                // map all selected tags
                if (
                  selectedtags.every((i) =>
                    iphonevideo.tags.split(", ").includes(i)
                  )
                ) {
                  setIphonevideos((iphonevideos) => [
                    ...iphonevideos,
                    {
                      url: iphonevideo.url,
                      orientation: iphonevideo.orientation,
                      tags: iphonevideo.tags,
                      id: iphonevideo.id,
                      title: iphonevideo.title,
                      type: iphonevideo.type,

                      project: item.title,
                    },
                  ]);
                }
                // selectedtags.map((projecttag) => {
                //   console.log(selectedtags);
                //   // console.log(projecttag);
                //   // map all tags from the image
                //   iphonevideo.tags.split(", ").map((iphonevideotag) => {
                //     // if according
                //     if (iphonevideotag === projecttag) {
                //       // console.log(iphonevideo);
                //       // if (images.map((img) => img.id === image.id)) return;
                //       setIphonevideos((iphonevideos) => [
                //         ...iphonevideos,
                //         {
                //           url: iphonevideo.url,
                //           width: iphonevideo.width,
                //           height: iphonevideo.height,
                //           tags: iphonevideo.tags,
                //           id: iphonevideo.id,
                //           title: iphonevideo.title,
                //           type: iphonevideo.type,

                //           project: item.title,
                //         },
                //       ]);
                //     }
                //   });
                // });
                // no tag and no title is selcted
              } else {
                setIphonevideos((iphonevideos) => [
                  ...iphonevideos,
                  {
                    url: iphonevideo.url,
                    orientation: iphonevideo.orientation,
                    tags: iphonevideo.tags,
                    id: iphonevideo.id,
                    title: iphonevideo.title,
                    type: iphonevideo.type,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setIphonevideos((iphonevideos) => [
                      ...iphonevideos,
                      {
                        url: iphonevideo.url,
                        orientation: iphonevideo.orientation,
                        tags: iphonevideo.tags,
                        id: iphonevideo.id,
                        title: iphonevideo.title,
                        type: iphonevideo.type,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  // check if tags
                  if (
                    selectedtags.every((i) =>
                      iphonevideo.tags.split(", ").includes(i)
                    )
                  ) {
                    if (item.title === projecttitle) {
                      setIphonevideos((iphonevideos) => [
                        ...iphonevideos,
                        {
                          url: iphonevideo.url,
                          orientation: iphonevideo.orientation,
                          tags: iphonevideo.tags,
                          id: iphonevideo.id,
                          title: iphonevideo.title,
                          type: iphonevideo.type,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                });
              }
            }
          });
        }
        if (item.documents) {
          // loop all images
          item.documents.map((document, i) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                if (
                  selectedtags.every((i) =>
                    document.tags.split(", ").includes(i)
                  )
                ) {
                  setDocuments((documents) => [
                    ...documents,
                    {
                      url: document.url,
                      width: document.width,
                      height: document.height,
                      tags: document.tags,
                      id: document.id,
                      title: document.title,
                      type: document.type,
                      folder: document.folder,

                      project: item.title,
                    },
                  ]);
                }
                // selectedtags.map((projecttag) => {
                //   // map all tags from the image
                //   document.tags.split(", ").map((documenttag) => {
                //     // if according
                //     if (documenttag === projecttag) {
                //       setDocuments((documents) => [
                //         ...documents,
                //         {
                //           url: document.url,
                //           width: document.width,
                //           height: document.height,
                //           tags: document.tags,
                //           id: document.id,
                //           title: document.title,
                //           type: document.type,
                //           folder: document.folder,

                //           project: item.title,
                //         },
                //       ]);
                //     }
                //   });
                // });
                // no tag and no title is selcted
              } else {
                // console.log(documents);
                setDocuments((documents) => [
                  ...documents,
                  {
                    url: document.url,
                    width: document.width,
                    height: document.height,
                    tags: document.tags,
                    id: document.id,
                    title: document.title,
                    type: document.type,
                    folder: document.folder,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setDocuments((documents) => [
                      ...documents,
                      {
                        url: document.url,
                        width: document.width,
                        height: document.height,
                        tags: document.tags,
                        id: document.id,
                        title: document.title,
                        type: document.type,
                        folder: document.folder,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  if (
                    selectedtags.every((i) =>
                      document.tags.split(", ").includes(i)
                    )
                  ) {
                    if (item.title === projecttitle) {
                      setDocuments((documents) => [
                        ...documents,
                        {
                          url: document.url,
                          width: document.width,
                          height: document.height,
                          tags: document.tags,
                          id: document.id,
                          title: document.title,
                          type: document.type,
                          folder: document.folder,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                });
                // selectedtitles.map((projecttitle) => {
                //   // check if tags
                //   selectedtags.map((projecttag) => {
                //     document.tags.split(", ").map((documenttag) => {
                //       if (documenttag === projecttag) {
                //         if (item.title === projecttitle) {
                //           setDocuments((documents) => [
                //             ...documents,
                //             {
                //               url: document.url,
                //               width: document.width,
                //               height: document.height,
                //               tags: document.tags,
                //               id: document.id,
                //               title: document.title,
                //               type: document.type,
                //               folder: document.folder,

                //               project: item.title,
                //             },
                //           ]);
                //         }
                //       }
                //     });
                //   });
                // });
              }
            }
          });
        } //////////////// webgls
        if (item.webgls) {
          // loop all images
          item.webgls.map((webgl) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // map all tags from the image
                  // map all selected tags
                  if (
                    selectedtags.every((i) =>
                      webgl.tags.split(", ").includes(i)
                    )
                  ) {
                    setWebgls((webgls) => [
                      ...webgls,
                      {
                        small: webgl.small,
                        previewtype: webgl.previewtype,
                        big: webgl.big,
                        size: webgl.size,
                        width: webgl.width,
                        height: webgl.height,
                        tags: webgl.tags,
                        id: webgl.id,
                        title: webgl.title,
                        type: webgl.type,
                        extension: webgl.extension,

                        project: item.title,
                      },
                    ]);
                  }
                  // webgl.tags.split(", ").map((webgltag) => {
                  //   // if according
                  //   if (webgltag === projecttag) {
                  //     setWebgls((webgls) => [
                  //       ...webgls,
                  //       {
                  //         small: webgl.small,
                  //         previewtype: webgl.previewtype,
                  //         big: webgl.big,
                  //         size: webgl.size,
                  //         width: webgl.width,
                  //         height: webgl.height,
                  //         tags: webgl.tags,
                  //         id: webgl.id,
                  //         title: webgl.title,
                  //         type: webgl.type,
                  //         extension: webgl.extension,

                  //         project: item.title,
                  //       },
                  //     ]);
                  //   }
                  // });
                });
                // no tag and no title is selcted
              } else {
                setWebgls((webgls) => [
                  ...webgls,
                  {
                    small: webgl.small,
                    previewtype: webgl.previewtype,
                    big: webgl.big,
                    size: webgl.size,
                    width: webgl.width,
                    height: webgl.height,
                    tags: webgl.tags,
                    id: webgl.id,
                    title: webgl.title,
                    type: webgl.type,
                    extension: webgl.extension,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    // console.log(images);
                    // if (images.map((img) => img.id === image.id)) return;
                    setWebgls((webgls) => [
                      ...webgls,
                      {
                        small: webgl.small,
                        previewtype: webgl.previewtype,
                        big: webgl.big,
                        size: webgl.size,
                        width: webgl.width,
                        height: webgl.height,
                        tags: webgl.tags,
                        id: webgl.id,
                        title: webgl.title,
                        type: webgl.type,
                        extension: webgl.extension,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  // check if tags
                  if (
                    selectedtags.every((i) =>
                      webgl.tags.split(", ").includes(i)
                    )
                  ) {
                    if (item.title === projecttitle) {
                      setWebgls((webgls) => [
                        ...webgls,
                        {
                          small: webgl.small,
                          previewtype: webgl.previewtype,
                          big: webgl.big,
                          size: webgl.size,
                          width: webgl.width,
                          height: webgl.height,
                          tags: webgl.tags,
                          id: webgl.id,
                          title: webgl.title,
                          type: webgl.type,
                          extension: webgl.extension,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                  // selectedtags.map((projecttag) => {
                  //   webgl.tags.split(", ").map((webgltag) => {
                  //     if (webgltag === projecttag) {
                  //       if (item.title === projecttitle) {
                  //         setWebgls((webgls) => [
                  //           ...documents,
                  //           {
                  //             small: webgl.small,
                  //             previewtype: webgl.previewtype,
                  //             big: webgl.big,
                  //             size: webgl.size,
                  //             width: webgl.width,
                  //             height: webgl.height,
                  //             tags: webgl.tags,
                  //             id: webgl.id,
                  //             title: webgl.title,
                  //             type: webgl.type,
                  //             extension: webgl.extension,

                  //             project: item.title,
                  //           },
                  //         ]);
                  //       }
                  //     }
                  //   });
                  // });
                });
              }
            }
          });
        }
        //////////////// texts
        if (item.texts) {
          // loop all images
          item.texts.map((text) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                if (
                  selectedtags.every((i) => text.tags.split(", ").includes(i))
                ) {
                  setTexts((texts) => [
                    ...texts,
                    {
                      tags: text.tags,
                      short: text.short,
                      id: text.id,
                      width: 200,
                      height: 100,
                      type: text.type,
                      textcontent: text.textcontent,
                      project: item.title,
                    },
                  ]);
                }

                // text.tags.split(", ").map((texttag) => {
                //   // if according
                //   if (texttag === projecttag) {
                //     // console.log(images);
                //     // if (images.map((img) => img.id === image.id)) return;
                //     setTexts((texts) => [
                //       ...texts,
                //       {
                //         tags: text.tags,
                //         short: text.short,
                //         id: text.id,
                //         width: 200,
                //         height: 100,
                //         type: text.type,
                //         textcontent: text.textcontent,
                //         project: item.title,
                //       },
                //     ]);
                //   }
                // });
                // });
                // no tag and no title is selcted
              } else {
                setTexts((texts) => [
                  ...texts,
                  {
                    tags: text.tags,
                    id: text.id,
                    short: text.short,
                    width: 200,
                    height: 100,
                    type: text.type,
                    textcontent: text.textcontent,
                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    // console.log(images);
                    // if (images.map((img) => img.id === image.id)) return;
                    setTexts((texts) => [
                      ...texts,
                      {
                        tags: text.tags,
                        id: text.id,
                        short: text.short,
                        width: 200,
                        height: 100,
                        type: text.type,
                        textcontent: text.textcontent,
                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  // check if tags
                  if (
                    selectedtags.every((i) => text.tags.split(", ").includes(i))
                  ) {
                    if (item.title === projecttitle) {
                      setTexts((texts) => [
                        ...texts,
                        {
                          tags: text.tags,
                          id: text.id,
                          short: text.short,
                          width: 200,
                          height: 100,
                          type: text.type,
                          textcontent: text.textcontent,
                          project: item.title,
                        },
                      ]);
                    }
                  }
                  // selectedtags.map((projecttag) => {
                  //   text.tags.split(", ").map((texttag) => {
                  //     if (texttag === projecttag) {
                  //       if (item.title === projecttitle) {
                  //         setTexts((texts) => [
                  //           ...texts,
                  //           {
                  //             tags: text.tags,
                  //             id: text.id,
                  //             short: text.short,
                  //             width: 200,
                  //             height: 100,
                  //             type: text.type,
                  //             textcontent: text.textcontent,
                  //             project: item.title,
                  //           },
                  //         ]);
                  //       }
                  //     }
                  //   });
                  // });
                });
              }
            }
          });
        }
        //////////////// videos
        if (item.videos) {
          // loop all images
          item.videos.map((video) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                // map all selected tags
                if (
                  selectedtags.every((i) => video.tags.split(", ").includes(i))
                ) {
                  setVideos((videos) => [
                    ...videos,
                    {
                      tags: video.tags,
                      id: video.id,
                      preview: video.preview,
                      url: video.url,
                      width: 16,
                      height: 9,
                      type: video.type,
                      project: item.title,
                    },
                  ]);
                }
                // selectedtags.map((projecttag) => {
                //   // map all tags from the image
                //   video.tags.split(", ").map((videotag) => {
                //     // if according
                //     if (videotag === projecttag) {
                //       setVideos((videos) => [
                //         ...videos,
                //         {
                //           tags: video.tags,
                //           id: video.id,
                //           url: video.url,
                //           width: 16,
                //           height: 9,
                //           type: video.type,
                //           project: item.title,
                //         },
                //       ]);
                //     }
                //   });
                // });
                // no tag and no title is selcted
              } else {
                setVideos((videos) => [
                  ...videos,
                  {
                    tags: video.tags,
                    id: video.id,
                    url: video.url,
                    preview: video.preview,
                    width: 16,
                    height: 9,
                    type: video.type,
                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    // console.log(images);
                    // if (images.map((img) => img.id === image.id)) return;
                    setVideos((videos) => [
                      ...videos,
                      {
                        tags: video.tags,
                        id: video.id,
                        url: video.url,
                        preview: video.preview,
                        width: 16,
                        height: 9,
                        type: video.type,
                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  // check if tags
                  if (
                    selectedtags.every((i) =>
                      video.tags.split(", ").includes(i)
                    )
                  ) {
                    if (item.title === projecttitle) {
                      setVideos((videos) => [
                        ...videos,
                        {
                          tags: video.tags,
                          id: video.id,
                          url: video.url,
                          preview: video.preview,
                          width: 16,
                          height: 9,
                          type: video.type,
                          project: item.title,
                        },
                      ]);
                    }
                  }
                  // selectedtags.map((projecttag) => {
                  //   video.tags.split(", ").map((videotag) => {
                  //     if (videotag === projecttag) {
                  //       if (item.title === projecttitle) {
                  //         setVideos((videos) => [
                  //           ...videos,
                  //           {
                  //             tags: video.tags,
                  //             id: video.id,
                  //             url: video.url,
                  //             width: 16,
                  //             height: 9,
                  //             type: video.type,
                  //             project: item.title,
                  //           },
                  //         ]);
                  //       }
                  //     }
                  //   });
                  // });
                });
              }
            }
          });
        }
        ////// publications
        if (item.publications) {
          // loop all images
          item.publications.map((publication, i) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                if (
                  selectedtags.every((i) =>
                    publication.tags.split(", ").includes(i)
                  )
                ) {
                  setPublications((publications) => [
                    ...publications,
                    {
                      url: publication.url,
                      small: publication.small,
                      big: publication.big,
                      width: publication.width,
                      height: publication.height,
                      publikationurl: publication.publikationurl,
                      publikationtitel: publication.publikationtitel,
                      tags: publication.tags,
                      id: publication.id,
                      title: publication.title,
                      type: publication.type,
                      folder: publication.folder,

                      project: item.title,
                    },
                  ]);
                }
                // selectedtags.map((projecttag) => {
                //   // map all tags from the image
                //   publication.tags.split(", ").map((publicationtag) => {
                //     // if according
                //     if (publicationtag === projecttag) {
                //       setPublications((publications) => [
                //         ...publications,
                //         {
                //           url: publication.url,
                //           small: publication.small,
                //           big: publication.big,
                //           width: publication.width,
                //           height: publication.height,
                //           publikationurl: publication.publikationurl,
                //           publikationtitel: publication.publikationtitel,
                //           tags: publication.tags,
                //           id: publication.id,
                //           title: publication.title,
                //           type: publication.type,
                //           folder: publication.folder,

                //           project: item.title,
                //         },
                //       ]);
                //     }
                //   });
                // });
                // no tag and no title is selcted
              } else {
                // console.log(publications);
                setPublications((publications) => [
                  ...publications,
                  {
                    url: publication.url,
                    small: publication.small,
                    big: publication.big,
                    width: publication.width,
                    height: publication.height,
                    publikationurl: publication.publikationurl,
                    publikationtitel: publication.publikationtitel,
                    tags: publication.tags,
                    id: publication.id,
                    title: publication.title,
                    type: publication.type,
                    folder: publication.folder,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setPublications((publications) => [
                      ...publications,
                      {
                        url: publication.url,
                        small: publication.small,
                        big: publication.big,
                        width: publication.width,
                        height: publication.height,
                        publikationurl: publication.publikationurl,
                        publikationtitel: publication.publikationtitel,
                        tags: publication.tags,
                        id: publication.id,
                        title: publication.title,
                        type: publication.type,
                        folder: publication.folder,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  // check if tags
                  if (
                    selectedtags.every((i) =>
                      publication.tags.split(", ").includes(i)
                    )
                  ) {
                    if (item.title === projecttitle) {
                      setPublications((publications) => [
                        ...publications,
                        {
                          url: publication.url,
                          small: publication.small,
                          big: publication.big,
                          width: publication.width,
                          height: publication.height,
                          publikationurl: publication.publikationurl,
                          publikationtitel: publication.publikationtitel,
                          tags: publication.tags,
                          id: publication.id,
                          title: publication.title,
                          type: publication.type,
                          folder: publication.folder,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                  // selectedtags.map((projecttag) => {
                  //   publication.tags.split(", ").map((publicationtag) => {
                  //     if (publicationtag === projecttag) {
                  //       if (item.title === projecttitle) {
                  //         setPublications((publications) => [
                  //           ...publications,
                  //           {
                  //             url: publication.url,
                  //             small: publication.small,
                  //             big: publication.big,
                  //             width: publication.width,
                  //             height: publication.height,
                  //             publikationurl: publication.publikationurl,
                  //             publikationtitel: publication.publikationtitel,
                  //             tags: publication.tags,
                  //             id: publication.id,
                  //             title: publication.title,
                  //             type: publication.type,
                  //             folder: publication.folder,

                  //             project: item.title,
                  //           },
                  //         ]);
                  //       }
                  //     }
                  //   });
                  // });
                });
              }
            }
          });
        }
      });
    }
  }, [data, selectedtags, selectedtitles]);

  useEffect(() => {
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(images);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(documents);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(webgls);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(texts);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(videos);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(publications);
    //   })
    // );
    let arr = [];
    let finarr = [];
    arr.push(images);
    arr.push(documents);
    arr.push(iphonevideos);
    arr.push(webgls);
    arr.push(texts);
    arr.push(publications);
    arr.push(videos);
    arr.map((items) => {
      items.map((item) => {
        finarr.push(item);
      });
    });
    useStore.setState(
      produce((state) => {
        state.data = finarr.sort((a, b) => a.project.localeCompare(b.project));
      })
    );
  }, [images, documents, webgls, texts, publications, videos, iphonevideos]);

  return (
    <>
      {images && documents && webgls && texts && videos && (
        <Shuffle
          images={images}
          documents={documents}
          webgls={webgls}
          texts={texts}
          iphonevideos={iphonevideos}
          videos={videos}
          publications={publications}
        />
      )}
    </>
  );
}
function FilterOld({ data }) {
  const selectedtitles = useStore((s) => s.selectedtitles);
  const selectedtags = useStore((s) => s.selectedtags);

  const [images, setImages] = useState([]);
  const [iphonevideos, setIphonevideos] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [texts, setTexts] = useState([]);
  const [webgls, setWebgls] = useState([]);
  const [videos, setVideos] = useState([]);
  const [publications, setPublications] = useState([]);

  function resetTags() {
    setImages([]);
    setIphonevideos([]);
    setDocuments([]);
    setWebgls([]);
    setTexts([]);
    setVideos([]);
    setPublications([]);
  }
  useEffect(() => {
    if (data) {
      data.map((item) => {
        // project title as tag
        if (item.title !== undefined) {
          useStore.setState(
            produce((state) => {
              if (
                !state.titletags.includes(item.title) &&
                item.title !== "NOT A PROJECT"
              )
                state.titletags.push(item.title);
            })
          );
        }

        // push tags to zustand
        if (item.tags) {
          item.tags.split(", ").map((tag) => {
            // useStore.setState((state) => state.projecttags.push(tag))
            useStore.setState(
              produce((state) => {
                if (!state.projecttags.includes(tag))
                  state.projecttags.push(tag);
              })
            );
          });
        }
        // console.log(item);
        if (item.contact) {
          // useStore.setState((state) => (state.contact = item.contact));
          useStore.setState(
            produce((state) => {
              state.contact = item.contact;
            })
          );
        }
        if (item.contacttwn) {
          // useStore.setState((state) => (state.contact = item.contact));
          useStore.setState(
            produce((state) => {
              state.contacttwn = item.contacttwn;
            })
          );
        }
      });
    }
  }, [data]);

  useEffect(() => {
    // reset stores
    resetTags();

    if (data) {
      data.map((item) => {
        // push images
        if (item.images) {
          // loop all images
          item.images.map((image) => {
            // console.log(image);
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // console.log(projecttag);
                  // console.log(selectedtags, image.tags);
                  // map all tags from the image
                  image.tags.split(", ").map((imagetag) => {
                    // if according
                    if (imagetag === projecttag) {
                      // console.log(images);
                      // if (images.map((img) => img.id === image.id)) return;
                      setImages((images) => [
                        ...images,
                        {
                          url: image.url,
                          small: image.small,
                          big: image.big,
                          width: image.width,
                          height: image.height,
                          tags: image.tags,
                          id: image.id,
                          title: image.title,
                          type: image.type,

                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                setImages((images) => [
                  ...images,
                  {
                    url: image.url,
                    small: image.small,
                    big: image.big,
                    width: image.width,
                    height: image.height,
                    tags: image.tags,
                    id: image.id,
                    title: image.title,
                    type: image.type,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setImages((images) => [
                      ...images,
                      {
                        url: image.url,
                        small: image.small,
                        big: image.big,
                        width: image.width,
                        height: image.height,
                        tags: image.tags,
                        id: image.id,
                        title: image.title,
                        type: image.type,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    image.tags.split(", ").map((imagetag) => {
                      if (imagetag === projecttag) {
                        if (item.title === projecttitle) {
                          setImages((images) => [
                            ...images,
                            {
                              url: image.url,
                              small: image.small,
                              big: image.big,
                              width: image.width,
                              height: image.height,
                              tags: image.tags,
                              id: image.id,
                              title: image.title,
                              type: image.type,

                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }
        // push iphonevideos
        if (item.iphonevideos) {
          // loop all iphonevideo
          item.iphonevideos.map((iphonevideo) => {
            // console.log(image);
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // console.log(selectedtags);
                  // console.log(projecttag);
                  // map all tags from the image
                  iphonevideo.tags.split(", ").map((iphonevideotag) => {
                    // if according
                    if (iphonevideotag === projecttag) {
                      // console.log(iphonevideo);
                      // if (images.map((img) => img.id === image.id)) return;
                      setIphonevideos((iphonevideos) => [
                        ...iphonevideos,
                        {
                          url: iphonevideo.url,
                          width: iphonevideo.width,
                          height: iphonevideo.height,
                          tags: iphonevideo.tags,
                          id: iphonevideo.id,
                          title: iphonevideo.title,
                          type: iphonevideo.type,

                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                setIphonevideos((iphonevideos) => [
                  ...iphonevideos,
                  {
                    url: iphonevideo.url,
                    width: iphonevideo.width,
                    height: iphonevideo.height,
                    tags: iphonevideo.tags,
                    id: iphonevideo.id,
                    title: iphonevideo.title,
                    type: iphonevideo.type,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setIphonevideos((iphonevideos) => [
                      ...iphonevideos,
                      {
                        url: iphonevideo.url,
                        width: iphonevideo.width,
                        height: iphonevideo.height,
                        tags: iphonevideo.tags,
                        id: iphonevideo.id,
                        title: iphonevideo.title,
                        type: iphonevideo.type,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    iphonevideo.tags.split(", ").map((iphonevideotag) => {
                      if (iphonevideotag === projecttag) {
                        if (item.title === projecttitle) {
                          setIphonevideos((iphonevideos) => [
                            ...iphonevideos,
                            {
                              url: iphonevideo.url,
                              width: iphonevideo.width,
                              height: iphonevideo.height,
                              tags: iphonevideo.tags,
                              id: iphonevideo.id,
                              title: iphonevideo.title,
                              type: iphonevideo.type,

                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }

        // //////////////// documents
        if (item.documents) {
          // loop all images
          item.documents.map((document, i) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // map all tags from the image
                  document.tags.split(", ").map((documenttag) => {
                    // if according
                    if (documenttag === projecttag) {
                      setDocuments((documents) => [
                        ...documents,
                        {
                          url: document.url,
                          width: document.width,
                          height: document.height,
                          tags: document.tags,
                          id: document.id,
                          title: document.title,
                          type: document.type,
                          folder: document.folder,

                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                // console.log(documents);
                setDocuments((documents) => [
                  ...documents,
                  {
                    url: document.url,
                    width: document.width,
                    height: document.height,
                    tags: document.tags,
                    id: document.id,
                    title: document.title,
                    type: document.type,
                    folder: document.folder,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setDocuments((documents) => [
                      ...documents,
                      {
                        url: document.url,
                        width: document.width,
                        height: document.height,
                        tags: document.tags,
                        id: document.id,
                        title: document.title,
                        type: document.type,
                        folder: document.folder,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    document.tags.split(", ").map((documenttag) => {
                      if (documenttag === projecttag) {
                        if (item.title === projecttitle) {
                          setDocuments((documents) => [
                            ...documents,
                            {
                              url: document.url,
                              width: document.width,
                              height: document.height,
                              tags: document.tags,
                              id: document.id,
                              title: document.title,
                              type: document.type,
                              folder: document.folder,

                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }
        //////////////// webgls
        if (item.webgls) {
          // loop all images
          item.webgls.map((webgl) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // map all tags from the image
                  webgl.tags.split(", ").map((webgltag) => {
                    // if according
                    if (webgltag === projecttag) {
                      setWebgls((webgls) => [
                        ...webgls,
                        {
                          small: webgl.small,
                          previewtype: webgl.previewtype,
                          big: webgl.big,
                          size: webgl.size,
                          width: webgl.width,
                          height: webgl.height,
                          tags: webgl.tags,
                          id: webgl.id,
                          title: webgl.title,
                          type: webgl.type,
                          extension: webgl.extension,

                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                setWebgls((webgls) => [
                  ...webgls,
                  {
                    small: webgl.small,
                    previewtype: webgl.previewtype,
                    big: webgl.big,
                    size: webgl.size,
                    width: webgl.width,
                    height: webgl.height,
                    tags: webgl.tags,
                    id: webgl.id,
                    title: webgl.title,
                    type: webgl.type,
                    extension: webgl.extension,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    // console.log(images);
                    // if (images.map((img) => img.id === image.id)) return;
                    setWebgls((webgls) => [
                      ...webgls,
                      {
                        small: webgl.small,
                        previewtype: webgl.previewtype,
                        big: webgl.big,
                        size: webgl.size,
                        width: webgl.width,
                        height: webgl.height,
                        tags: webgl.tags,
                        id: webgl.id,
                        title: webgl.title,
                        type: webgl.type,
                        extension: webgl.extension,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    webgl.tags.split(", ").map((webgltag) => {
                      if (webgltag === projecttag) {
                        if (item.title === projecttitle) {
                          setWebgls((webgls) => [
                            ...documents,
                            {
                              small: webgl.small,
                              previewtype: webgl.previewtype,
                              big: webgl.big,
                              size: webgl.size,
                              width: webgl.width,
                              height: webgl.height,
                              tags: webgl.tags,
                              id: webgl.id,
                              title: webgl.title,
                              type: webgl.type,
                              extension: webgl.extension,

                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }

        //////////////// texts
        if (item.texts) {
          // loop all images
          item.texts.map((text) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // console.log(projecttag);
                  // map all tags from the image
                  text.tags.split(", ").map((texttag) => {
                    // if according
                    if (texttag === projecttag) {
                      // console.log(images);
                      // if (images.map((img) => img.id === image.id)) return;
                      setTexts((texts) => [
                        ...texts,
                        {
                          tags: text.tags,
                          short: text.short,
                          id: text.id,
                          width: 200,
                          height: 100,
                          type: text.type,
                          textcontent: text.textcontent,
                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                setTexts((texts) => [
                  ...texts,
                  {
                    tags: text.tags,
                    id: text.id,
                    short: text.short,
                    width: 200,
                    height: 100,
                    type: text.type,
                    textcontent: text.textcontent,
                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    // console.log(images);
                    // if (images.map((img) => img.id === image.id)) return;
                    setTexts((texts) => [
                      ...texts,
                      {
                        tags: text.tags,
                        id: text.id,
                        short: text.short,
                        width: 200,
                        height: 100,
                        type: text.type,
                        textcontent: text.textcontent,
                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    text.tags.split(", ").map((texttag) => {
                      if (texttag === projecttag) {
                        if (item.title === projecttitle) {
                          setTexts((texts) => [
                            ...texts,
                            {
                              tags: text.tags,
                              id: text.id,
                              short: text.short,
                              width: 200,
                              height: 100,
                              type: text.type,
                              textcontent: text.textcontent,
                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }

        //////////////// videos
        if (item.videos) {
          // loop all images
          item.videos.map((video) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // map all tags from the image
                  video.tags.split(", ").map((videotag) => {
                    // if according
                    if (videotag === projecttag) {
                      setVideos((videos) => [
                        ...videos,
                        {
                          tags: video.tags,
                          id: video.id,
                          url: video.url,
                          width: 16,
                          height: 9,
                          type: video.type,
                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                setVideos((videos) => [
                  ...videos,
                  {
                    tags: video.tags,
                    id: video.id,
                    url: video.url,
                    width: 16,
                    height: 9,
                    type: video.type,
                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    // console.log(images);
                    // if (images.map((img) => img.id === image.id)) return;
                    setVideos((videos) => [
                      ...videos,
                      {
                        tags: video.tags,
                        id: video.id,
                        url: video.url,
                        width: 16,
                        height: 9,
                        type: video.type,
                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    video.tags.split(", ").map((videotag) => {
                      if (videotag === projecttag) {
                        if (item.title === projecttitle) {
                          setVideos((videos) => [
                            ...videos,
                            {
                              tags: video.tags,
                              id: video.id,
                              url: video.url,
                              width: 16,
                              height: 9,
                              type: video.type,
                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }
        //

        ////// publications
        if (item.publications) {
          // loop all images
          item.publications.map((publication, i) => {
            // no title selected
            if (selectedtitles?.length === 0) {
              // only if only tags are selected
              if (selectedtags?.length !== 0) {
                // map all selected tags
                selectedtags.map((projecttag) => {
                  // map all tags from the image
                  publication.tags.split(", ").map((publicationtag) => {
                    // if according
                    if (publicationtag === projecttag) {
                      setPublications((publications) => [
                        ...publications,
                        {
                          url: publication.url,
                          small: publication.small,
                          big: publication.big,
                          width: publication.width,
                          height: publication.height,
                          publikationurl: publication.publikationurl,
                          publikationtitel: publication.publikationtitel,
                          tags: publication.tags,
                          id: publication.id,
                          title: publication.title,
                          type: publication.type,
                          folder: publication.folder,

                          project: item.title,
                        },
                      ]);
                    }
                  });
                });
                // no tag and no title is selcted
              } else {
                // console.log(publications);
                setPublications((publications) => [
                  ...publications,
                  {
                    url: publication.url,
                    small: publication.small,
                    big: publication.big,
                    width: publication.width,
                    height: publication.height,
                    publikationurl: publication.publikationurl,
                    publikationtitel: publication.publikationtitel,
                    tags: publication.tags,
                    id: publication.id,
                    title: publication.title,
                    type: publication.type,
                    folder: publication.folder,

                    project: item.title,
                  },
                ]);
              }
            }
            // no tag but title selected
            else {
              if (selectedtags?.length === 0) {
                // map all selected tags
                selectedtitles.map((projecttitle) => {
                  // map all tags from the image
                  // if according
                  if (item.title === projecttitle) {
                    setPublications((publications) => [
                      ...publications,
                      {
                        url: publication.url,
                        small: publication.small,
                        big: publication.big,
                        width: publication.width,
                        height: publication.height,
                        publikationurl: publication.publikationurl,
                        publikationtitel: publication.publikationtitel,
                        tags: publication.tags,
                        id: publication.id,
                        title: publication.title,
                        type: publication.type,
                        folder: publication.folder,

                        project: item.title,
                      },
                    ]);
                  }
                  // });
                });
                // if tags and title
              } else {
                // check if title
                selectedtitles.map((projecttitle) => {
                  // check if tags
                  selectedtags.map((projecttag) => {
                    publication.tags.split(", ").map((publicationtag) => {
                      if (publicationtag === projecttag) {
                        if (item.title === projecttitle) {
                          setPublications((publications) => [
                            ...publications,
                            {
                              url: publication.url,
                              small: publication.small,
                              big: publication.big,
                              width: publication.width,
                              height: publication.height,
                              publikationurl: publication.publikationurl,
                              publikationtitel: publication.publikationtitel,
                              tags: publication.tags,
                              id: publication.id,
                              title: publication.title,
                              type: publication.type,
                              folder: publication.folder,

                              project: item.title,
                            },
                          ]);
                        }
                      }
                    });
                  });
                });
              }
            }
          });
        }
      });
    }
  }, [data, selectedtags, selectedtitles]);

  useEffect(() => {
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(images);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(documents);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(webgls);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(texts);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(videos);
    //   })
    // );
    // useStore.setState(
    //   produce((state) => {
    //     state.data.push(publications);
    //   })
    // );
    let arr = [];
    let finarr = [];
    arr.push(images);
    arr.push(documents);
    arr.push(iphonevideos);
    arr.push(webgls);
    arr.push(texts);
    arr.push(publications);
    arr.push(videos);
    arr.map((items) => {
      items.map((item) => {
        finarr.push(item);
      });
    });
    useStore.setState(
      produce((state) => {
        state.data = finarr.sort((a, b) => a.project.localeCompare(b.project));
      })
    );
  }, [images, documents, webgls, texts, publications, videos, iphonevideos]);

  return (
    <>
      {images && documents && webgls && texts && videos && (
        <Shuffle
          images={images}
          documents={documents}
          webgls={webgls}
          texts={texts}
          iphonevideos={iphonevideos}
          videos={videos}
          publications={publications}
        />
      )}
    </>
  );
}

function Post() {
  const data = suspend(async () => {
    const res = await fetch(
      `https://unprofessional.studio/backend/datastudio.json`
    );
    return await res.json();
  }, []);
  return <Filter data={data} />;
}

function App() {
  const [showIntro, setShowIntro] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowIntro(false);
    }, 6200);
  }, []);
  return (
    <>
      <Suspense fallback={<Intro />}>
        <Post />
      </Suspense>
      <Button />
      <Content />
      <Contact />
      {/* {showIntro && <Intro />} */}
    </>
  );
}

export default App;
